import { createTheme } from "@mui/material/styles";

const { palette } = createTheme({
  palette: {
    primary: {
      main: "#f0cd87",
      contrastText: "#0c0d12",
    },
    secondary: {
      main: "#d32f2f",
      contrastText: "#0c0d12",
    },
    info: {
      main: "#0288d1",
      contrastText: "#0c0d12",
    },
    error: {
      main: "#d32f2f",
      contrastText: "#0c0d12",
    },
    background: {
      default: "#11131a",
      paper: "#4a4343",
    },
    text: {
      primary: "#abb0c5",
    },
  },
});

const theme = createTheme({
  palette,
  typography: {
    h1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      textAlign: "center",
      margin: "0",
      padding: "30px 0",
      color: palette.text.primary,
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      textAlign: "center",
      margin: "0 15px",
      padding: "30px 0",
      color: palette.text.primary,
    },
    h3: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      textAlign: "center",
      margin: "0 15px",
      padding: "30px 0",
      color: palette.text.primary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          fontSize: "18px",
          width: "100%",
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: `${palette.primary.main} !important`,
            color: palette.primary.contrastText,
            fontStyle: "normal",
            transition: "background 0.7s",
            margin: "30px 0",
            "&.Mui-disabled": {
              backgroundColor: "#6f6f6f !important",
              color: palette.action.disabled,
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            fontStyle: "normal",
            textTransform: "uppercase",
            background: "none !important",
            color: palette.text.primary,
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "15px",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          fontSize: "18px",
        },
      },
    },
  },
});

export default theme;
