import { RoleInterface } from "common/interfaces";
import { useRoleList } from "../hooks";
import { RoleItem } from "./RoleItem";
import { Box, Typography } from "@mui/material";

interface Props {
  roles: RoleInterface[];
}

export const RolesList = ({ roles }: Props) => {
  const dataRoleList = useRoleList(roles);

  return (
    <Box>
      <Typography sx={{ padding: "0 0 15px 0" }} variant="h2">
        Ролі
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {dataRoleList.map(({ id, name, count }) => (
          <RoleItem name={name} count={count} key={id} />
        ))}
      </Box>
    </Box>
  );
};
