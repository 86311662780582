import { ReactNode } from "react";
import {
  GamerListElementVariantType,
  GamerListSubmitType,
} from "../interfaces";

interface Props {
  variant: GamerListElementVariantType;
  onSubmit?: GamerListSubmitType;
  children: ReactNode;
}

export function GamerListElement({ children }: Props) {
  return <>{children}</>;
}
