import { useStartGame } from "common/hooks/useStartGame";
import { Box, Button } from "@mui/material";

export const StartGame = () => {
  const { isStartGame, startGame } = useStartGame();

  if (isStartGame) return <></>;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px 15px",
        minHeight: "500px",
        margin: "0 -24px",
        backgroundImage: `url(img/bg.webp)`,
        backgroundSize: "cover",
        backgroundPosition: "-50px center",
      }}
    >
      <Button onClick={startGame} variant="contained">
        Почати гру
      </Button>
    </Box>
  );
};
