import { OnFinishAbilityInterface } from "../../../interfaces";
import { useEffect, useState } from "react";
import { useCheckSheriffRole } from "../../../hooks";

export const useAdmirer = (
  onFinishAbility: OnFinishAbilityInterface,
  gamerId: number
) => {
  const [gamerIdValue, setGamerIdValue] = useState("");

  //const { registerNightAction } = useNightActions();

  const checkedSherifData = useCheckSheriffRole(Number(gamerIdValue));

  const onChangeGamerId = (id: string) => {
    setGamerIdValue(id);
  };

  const onConfirmAbility = () => {
    if (checkedSherifData) {
      // const { isSheriff, checkedGamer } = checkedSherifData;
    }
  };

  useEffect(() => {
    if (gamerIdValue) onConfirmAbility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamerIdValue]);

  return {
    onChangeGamerId,
    gamerIdValue,
    onNext: () => onFinishAbility("success"),
  };
};
