import { RoleInterface } from "common/interfaces";

export const roles: RoleInterface[] = [
  {
    id: "scrounger",
    type: "mafia",
    name: "Крадій",
    shortName: "КР",
    color: "black",
    isActiveNight: true,
    isDisabled: false,
    priority: 1,
    desctiption:
      "Грає за мафію, Просинаеться у ночі. краде можливість у гравця зробити свій хід",
    abilities: ["block"],
  },
  {
    id: "doctor",
    name: "Лікарка",
    shortName: "ЛК",
    type: "city",
    color: "red",
    isActiveNight: true,
    isDisabled: false,
    priority: 2,
    desctiption:
      "Грає за мирних, Просинається у ночі та лікує одного гравця, не можно лікувати себе чи іншого гравця 2 рази підряд",
    abilities: ["healing"],
  },
  {
    id: "maniac",
    name: "Маніяк",
    shortName: "МН",
    type: "self",
    color: "blue",
    isActiveNight: true,
    isDisabled: false,
    priority: 3,
    desctiption:
      "Просинаеться у ночі, Грає самостійно від мафії та мирних стріляє у одного з участників",
    abilities: ["killing"],
  },
  {
    id: "mafia",
    name: "Загальний хід мафії",
    shortName: "М",
    type: "mafia",
    color: "black",
    isActiveNight: true,
    isDisabled: false,
    priority: 4,
    desctiption: "Мафія просинається у ночі та робить постріл",
    abilities: ["killing"],
  },
  {
    id: "godfather",
    name: "Хр. Батько",
    shortName: "ХБ",
    type: "mafia",
    color: "black",
    isActiveNight: false,
    isDisabled: false,
    priority: 4,
    desctiption:
      "Лідер мафії, Просинаеться у ночі. Вирішує разом зі своєю командою в кого мафія буде стріляти у ночі",
    abilities: [],
  },
  {
    id: "mafiosi",
    name: "Мафіозі",
    shortName: "МФ",
    type: "mafia",
    color: "black",
    isActiveNight: false,
    isDisabled: false,
    priority: 4,
    desctiption: "Грає за мафію",
    abilities: [],
  },
  {
    id: "chameleon",
    name: "Хамелеон",
    shortName: "ХМ",
    type: "mafia",
    color: "red",
    isActiveNight: false,
    isDisabled: false,
    priority: 4,
    desctiption:
      "Грає за мафію при перевірці завжди буде як звичайний мешканець міста",
    abilities: [],
  },
  {
    id: "sheriff",
    name: "Шериф",
    shortName: "ШР",
    type: "city",
    color: "red",
    isActiveNight: true,
    isDisabled: false,
    priority: 5,
    desctiption:
      "Грає за мирних, Шериф просинається у ночі та має змогу перевіряти або стріляти",
    abilities: ["checkPersonTeam", "killing"],
  },
  {
    id: "detective",
    name: "Детектив",
    shortName: "ДТ",
    type: "city",
    color: "red",
    isActiveNight: true,
    isDisabled: false,
    priority: 6,
    desctiption:
      "Грає за мирних, просинається у ночі, вибирає двух гравців, та може дізнатися грають вони за одну команду або ні",
    abilities: ["comparePersonsTeam"],
  },
  {
    id: "admirer",
    name: "Фанатка",
    shortName: "ФН",
    type: "city",
    color: "red",
    isActiveNight: true,
    isDisabled: false,
    priority: 7,
    desctiption:
      "Грає за мирних, просинається у ночі та обирає одного гравця, якщо цей гравець шериф то дізнається його роль",
    abilities: ["checkSheriffRole"],
  },
  {
    id: "bomb",
    name: "Вибухівка",
    shortName: "ВБ",
    type: "city",
    color: "red",
    isActiveNight: false,
    isDisabled: false,
    priority: 8,
    desctiption:
      "Грає за мирних, якщо бомба вибуває у будь який спосіб з гри вона вибухає і вбиває два два номери біля себе ",
    abilities: ["killing"],
  },
  {
    id: "angeldeath",
    name: "Янгол смерті",
    shortName: "ЯН",
    type: "city",
    color: "red",
    isActiveNight: false,
    isDisabled: false,
    priority: 8,
    desctiption:
      "Грає за мирних, якщо Ангел вибуває у будь який спосіб з гри він забирає одного гравця з собою",
    abilities: ["killing"],
  },
  {
    id: "peaceful",
    name: "Мирний",
    shortName: "ММ",
    type: "city",
    color: "red",
    isActiveNight: false,
    isDisabled: false,
    priority: 8,
    desctiption: "Грає за мирних",
    abilities: [],
  },
];
