import { ReactElement, Children, cloneElement } from "react";
import { GamerListSubmitType } from "../interfaces";
import { GamerListElement } from "../components/GamerListElement";

interface UseElementsProps {
  onSubmitHandler: (callback: GamerListSubmitType) => void;
  children?: ReactElement | ReactElement[];
}

export const useElements = ({
  onSubmitHandler,
  children,
}: UseElementsProps) => {
  const mappedChildren = Children.map(children, (child: any) => {
    if (
      child.type.name === GamerListElement.name &&
      child.props.variant === "submit"
    ) {
      const children = cloneElement(child.props.children, {
        onClick: () => onSubmitHandler(child.props.onSubmit),
      });

      const newChild = cloneElement(child, { children });

      return newChild;
    }

    return [];
  });

  return mappedChildren;
};
