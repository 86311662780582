import { GamerInterface, RoleIdTypes } from "common/interfaces";

interface FilterProps {
  isActiveNight?: boolean;
  sortByGamerId?: boolean;
  isExcludeKilled?: boolean;
  iskilledNigthGamers?: boolean;
  excludeRole?: RoleIdTypes;
}

const createGamersBuilder = (gamers: GamerInterface[]) => {
  return {
    gamers: [...gamers],

    sortById() {
      this.gamers.sort((g1, g2) => g1.id - g2.id);
      return this;
    },
    filterActive() {
      this.gamers = this.gamers.filter(({ isActive }) => isActive);
    },
    filterExcludeKilled() {
      this.gamers = this.gamers.filter(({ isKilled }) => !isKilled);
      return this;
    },
    filterKilledNigthGamers() {
      this.gamers = this.gamers.filter(({ isKilled }) => isKilled);
      return this;
    },
    filterKilledNigth() {
      this.gamers = this.gamers.filter(({ isKilled }) => isKilled);
    },
    filterExcludeRole(roleId: RoleIdTypes) {
      this.gamers = this.gamers.filter(({ role: { id } }) => id !== roleId);
    },

    build() {
      return this.gamers;
    },
  };
};

export const useFilterGamers = (
  gamers: GamerInterface[],
  filter?: FilterProps
) => {
  const gamersBuilder = createGamersBuilder(gamers);

  gamersBuilder.filterActive();

  if (filter?.isExcludeKilled) {
    gamersBuilder.filterExcludeKilled();
  }

  if (filter?.iskilledNigthGamers) {
    gamersBuilder.filterKilledNigthGamers();
  }

  if (filter?.excludeRole) {
    gamersBuilder.filterExcludeRole(filter?.excludeRole);
  }

  if (filter?.sortByGamerId) {
    gamersBuilder.sortById();
  }

  return gamersBuilder.build();
};
