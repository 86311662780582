import { Grid, Button, Box } from "@mui/material";
import { Header } from "./components";
import { useElements, useGamerDisable, useGamerList } from "./hooks";
import {
  GamerListActionsInterface,
  GamerListInterface,
  onGamerListSelectGamersInterface,
  onGamerListSelectGamerInterface,
} from "./interfaces";
import { ReactElement } from "react";
import { RoleIdTypes } from "common/interfaces";

type ElementType = ReactElement & { btn?: "submit" | "cancel" };

interface Props {
  title?: string;
  isMultiple?: boolean;
  excludeRole?: RoleIdTypes;
  disableGamerId?: number | number[];
  isSubmitedReset?: boolean;
  isOnchangeGamerReset?: boolean;
  isRerenderGamers?: boolean;
  onGamersSelect?: onGamerListSelectGamersInterface;
  onGamerSelect?: onGamerListSelectGamerInterface;
  onSubmit?: (
    gamer: GamerListInterface,
    gamers: GamerListInterface[],
    actions: GamerListActionsInterface
  ) => void;
  children?: ElementType | ElementType[];
}

export const GamerList = ({
  title = "...Обрати",
  disableGamerId,
  onGamersSelect,
  onGamerSelect,
  isMultiple,
  isSubmitedReset,
  isRerenderGamers,
  children,
}: Props) => {
  const { gamers, selectedGamers, onSelectGamer, onSubmitHandler } =
    useGamerList({
      onGamersSelect,
      onGamerSelect,
      isMultiple,
      isSubmitedReset,
      isRerenderGamers,
    });

  const elements = useElements({ children, onSubmitHandler });

  const checkDisableGamer = useGamerDisable();

  return (
    <Box sx={{ width: "100%" }}>
      <Header selectedGamers={selectedGamers}>{title}</Header>
      <Grid
        sx={{
          "& .MuiButton-root": {
            margin: 0,
          },
        }}
        container
        justifyContent="center"
        spacing={2}
      >
        {gamers.map(({ id, shortName, selected, color }) => (
          <Grid key={id} item xs={4}>
            <Button
              disabled={checkDisableGamer(id, disableGamerId)}
              disableFocusRipple
              onClick={onSelectGamer(id)}
              sx={{
                "& .text": {
                  borderBottom: `5px solid ${color}`,
                },
                opacity: `${selected ? 0.4 : 1}`,
              }}
              variant="contained"
            >
              <span className="text">
                {id} {shortName}
              </span>
            </Button>
          </Grid>
        ))}
      </Grid>
      {elements}
    </Box>
  );
};
