/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Cooldown, useCooldownTimer } from "common/components";
import { useStartScene } from "common/hooks";
import { GamerCard, Navigation } from "./components";
import { useSpeech } from "./hooks";
import Slide from "@mui/material/Slide";
import { Box, Typography } from "@mui/material";

export const Speech = () => {
  const { isStart, runStart } = useStartScene();
  const { timer, onResetTimer } = useCooldownTimer(1, isStart);

  const {
    activeGamer,
    onNextGamer,
    onPrewGamer,
    onFinishTimer,
    onStart,
    isNext,
    isPrev,
    isEffect,
  } = useSpeech(onResetTimer, runStart);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Typography variant="h1">Індивідуальні промови</Typography>

      <Navigation
        isStart={isStart}
        onStart={onStart}
        onNextGamer={onNextGamer}
        onResetTimer={onResetTimer}
        isNext={isNext}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            margin: "20px 0",
            color: "white",
            ".MuiSvgIcon-root": {
              fontSize: "50px",
            },
            "& > span": {
              width: "50px",
            },
          }}
        >
          {isPrev && isStart ? (
            <ArrowBackIcon onClick={onPrewGamer} />
          ) : (
            <span />
          )}
          <Cooldown
            css={css`
              width: 100px;
            `}
            timer={timer}
            onFinishTimer={onFinishTimer}
          />
          {isNext && isStart ? (
            <ArrowForwardIcon onClick={onNextGamer} />
          ) : (
            <span />
          )}
        </Box>

        <Slide direction="left" in={isEffect} mountOnEnter unmountOnExit>
          <Box>
            <GamerCard gamer={activeGamer} />
          </Box>
        </Slide>
      </Box>
    </Box>
  );
};
