import { GamerList } from "common/components";
import { useAbility } from "common/hooks";
import { GamerPropsInterface } from "../../interfaces";

import { useAdmirer } from "./hooks";
import { Button } from "@mui/material";

export const Admirer = ({ gamer, onFinishAbility }: GamerPropsInterface) => {
  const ability = useAbility(gamer.role.abilities[0]);

  const { onNext } = useAdmirer(onFinishAbility, gamer.id);

  return (
    <>
      <GamerList
        disableGamerId={gamer.id}
        title={`Використати здібність "${ability.name}"`}
      />
      <Button onClick={onNext} variant="contained">
        Гравця перевірено
      </Button>
    </>
  );
};
