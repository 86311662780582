import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Dialog } from "common/components";
import React, { useEffect, useRef, useState } from "react";
import { IconDialogTypes } from "common/interfaces";
import { Root, createRoot } from "react-dom/client";

let prevRoot: Root | null = null;

interface RunDialogInterface {
  icon?: IconDialogTypes;
  title?: string;
  description?: string;
}

interface AlertDialogPropsInterface {
  icon?: IconDialogTypes;
  title?: string;
  description?: string;
  confirm?: boolean;
  reject?: boolean;
  next?: boolean;
  type?: "alert" | "confirm";
  reinstall?: boolean;
}

type AlertDialogResult = Promise<
  "confirmed" | "rejected" | "next" | "reinstall"
>;

export const useAlertDialog = ({
  icon,
  type,
  title,
  description,
  next,
  confirm,
  reject,
  reinstall,
}: AlertDialogPropsInterface) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>();
  const [data, setData] = useState<RunDialogInterface>({
    icon,
    title,
    description,
  });

  const portalRoot = useRef<any>(null);
  const resolveRef = useRef<(value: any) => void>();

  const element = React.createElement(
    ThemeProvider,
    { theme },
    React.createElement(Dialog, {
      icon: data.icon,
      type,
      title: data.title,
      description: data.description,
      isOpen,
      next,
      confirm,
      reject,
      reinstall,
      onSelect: (type) => {
        setIsOpen(false);
        if (resolveRef.current) resolveRef.current(type);
      },
    })
  );

  useEffect(() => {
    if (isOpen && !portalRoot.current) {
      const rootName = prevRoot ? "modal-root-next" : "modal-root";

      portalRoot.current = createRoot(document.getElementById(rootName) as any);

      prevRoot = portalRoot.current;
    }

    if (portalRoot.current) portalRoot.current.render(element);

    if (isOpen === false && portalRoot.current) {
      setTimeout(() => {
        portalRoot.current.unmount();
        portalRoot.current = null;
        prevRoot = null;
      }, 300);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (data?: RunDialogInterface): AlertDialogResult => {
    data && setData(data);
    setIsOpen(true);

    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  };
};
