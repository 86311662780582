interface Props {
  gamers: number[];
}

export const RoleGamerList = ({ gamers }: Props) => (
  <>
    {gamers.map((gamer) => (
      <span
        key={`${gamer}`}
        style={{
          display: "block",
          textAlign: "center",
          lineHeight: "30px",
          width: "40px",
          margin: "5px 0px 5px 5px",
          fontSize: "18px",
          background: "white",
          color: "black",
          fontWeight: "bold",
        }}
      >
        {gamer}
      </span>
    ))}
  </>
);
