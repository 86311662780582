import { GamerList, GamerListElement } from "common/components";
import { useAbility } from "common/hooks";
import { GamerPropsInterface } from "../../interfaces";

import { useDoctor, usePrevNigthHealing } from "./hooks";
import { Button } from "@mui/material";

export const Doctor = ({ gamer, onFinishAbility }: GamerPropsInterface) => {
  const ability = useAbility(gamer.role.abilities[0]);

  const { prevNigthGamerId } = usePrevNigthHealing();

  const { onChangeGamerId, onPushSelfAbility } = useDoctor(
    onFinishAbility,
    gamer.id
  );

  return (
    <>
      <GamerList
        disableGamerId={prevNigthGamerId}
        title={`Використати здібність "${ability.name}"`}
        onGamerSelect={onChangeGamerId}
      >
        <GamerListElement variant="submit" onSubmit={onPushSelfAbility}>
          <Button
            disabled={prevNigthGamerId === gamer.id}
            className="gamer-push-ability"
            variant="contained"
          >
            Використати на себе
          </Button>
        </GamerListElement>
      </GamerList>
    </>
  );
};
