import DialogMUI from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button } from "@mui/material";
import { IconDialogTypes } from "common/interfaces";
import { useDialogIcon, useDialogSetting } from "./hooks";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

interface Props {
  icon?: IconDialogTypes;
  type?: "alert" | "confirm";
  title?: string;
  description?: string;
  isOpen?: boolean;
  onConfirm?: () => void;
  onReject?: () => void;
  onNext?: () => void;
  onSelect?: (type: "confirmed" | "rejected" | "next" | "reinstall") => void;
  confirm?: boolean;
  reject?: boolean;
  next?: boolean;
  reinstall?: boolean;
}

export const Dialog = ({
  icon = "info",
  type,
  title,
  description,
  isOpen = false,
  onSelect = () => void 0,
  confirm,
  next,
  reject,
  reinstall,
}: Props) => {
  const Icon = useDialogIcon(icon);
  const dialogSetting = useDialogSetting(icon);

  return (
    <Box>
      <DialogMUI
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          {Icon && (
            <Icon
              sx={{
                color: `${dialogSetting.color}`,
                width: "40px",
                height: "40px",
                marginRight: "15px",
              }}
            />
          )}

          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {reinstall && (
              <Box
                component="span"
                sx={{
                  cursor: "pointer",
                  color: "secondary.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  maxWidth: "155px",
                }}
                onClick={() => onSelect("reinstall")}
              >
                <SyncAltIcon
                  sx={{
                    marginRight: "15px",
                  }}
                />
                оновити додаток
              </Box>
            )}
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            "& .MuiButton-root": {
              width: "200px",
              margin: "15px",
            },
          }}
        >
          {(confirm || type === "confirm") && (
            <Button
              variant="contained"
              onClick={() => onSelect("confirmed")}
              autoFocus
            >
              Так
            </Button>
          )}
          {(reject || type === "confirm") && (
            <Button variant="contained" onClick={() => onSelect("rejected")}>
              Ні
            </Button>
          )}
          {(next || type === "alert") && (
            <Button
              variant="contained"
              onClick={() => onSelect("next")}
              autoFocus
            >
              Далі
            </Button>
          )}
        </DialogActions>
      </DialogMUI>
    </Box>
  );
};
