import { useGamers } from "common/hooks";
import { GamerInterface } from "common/interfaces";

interface CompareReturnInterface {
  isTeam: boolean;
  gamerFirst: GamerInterface;
  gamerSecond: GamerInterface;
}

export const useComparePersonsTeam = () => {
  const { getGamerById } = useGamers();

  return (
    gamerFirstId: number,
    gamerSecondId: number
  ): CompareReturnInterface | null => {
    if (gamerFirstId && gamerSecondId) {
      const gamerFirst = getGamerById(gamerFirstId);
      const gamerSecond = getGamerById(gamerSecondId);

      let isTeam = gamerFirst.role.type === gamerSecond.role.type;

      if (
        gamerFirst.role.id === "chameleon" ||
        gamerSecond.role.id === "chameleon"
      ) {
        isTeam = !isTeam;
      }

      return {
        isTeam,
        gamerFirst,
        gamerSecond,
      };
    }
    return null;
  };
};
