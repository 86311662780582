/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useStartGame } from "common/hooks/useStartGame";

export const Header = () => {
  const { isStartGame } = useStartGame();

  if (isStartGame) return <></>;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "15px 0",
      }}
    >
      <Typography sx={{ padding: "0 0 15px 0" }} variant="h1">
        Welcome to the
      </Typography>
      <img
        css={css`
          max-width: 300px;
        `}
        src="img/logo.png"
        alt=""
      />
    </Box>
  );
};
