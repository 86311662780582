import { OnFinishAbilityInterface } from "../../../interfaces";

import { useNightActions, useComparePersonsTeam } from "../../../hooks";
import { useAlertDialog } from "common/hooks";

export const useDetective = (
  onFinishAbility: OnFinishAbilityInterface,
  gamerId: number
) => {
  const checkComparePersons = useComparePersonsTeam();

  const { registerNightAction } = useNightActions();

  const checkTeamDialog = useAlertDialog({
    type: "confirm",
  });

  const onConfirmAbility = async (ids: number[], reset: () => void) => {
    const checkComparePersonsData = checkComparePersons(
      Number(ids[0]),
      Number(ids[1])
    );

    if (checkComparePersonsData) {
      const { isTeam, gamerFirst, gamerSecond } = checkComparePersonsData;

      const type = await checkTeamDialog({
        icon: isTeam ? "yes" : "no",
        title: isTeam
          ? "Гравці грають за одну команду"
          : "Гравці грають за різні команди",
        description: "Перейти до наступного гравця ?",
      });

      if (type === "confirmed") {
        onFinishAbility("success");

        registerNightAction({
          abilityId: "checkPersonTeam",
          gamerIdFrom: gamerId,
          gamersIdsTo: [gamerFirst.id, gamerSecond.id],
        });
      }

      reset();
    }
  };

  return {
    onConfirmAbility,
    onNext: () => onFinishAbility("success"),
  };
};
