import { useSelector, selectGamers } from "redux-store";
import { GamerListInterface, GamerListSubmitType } from "../interfaces";
import { useEffect, useState } from "react";
import { useFilterGamers } from "common/hooks";
import { RoleIdTypes } from "common/interfaces";

interface UseGamerProps {
  onGamersSelect?: (gamers: GamerListInterface[], actions: any) => void;
  onGamerSelect?: (gamer: GamerListInterface, actions: any) => void;
  isMultiple?: boolean;
  isRerenderGamers?: boolean;
  isSubmitedReset?: boolean;
  excludeRole?: RoleIdTypes;
}

export const useGamerList = ({
  isMultiple,
  onGamerSelect,
  onGamersSelect,
  excludeRole,
  isRerenderGamers,
}: UseGamerProps) => {
  const dataGamers = useSelector(selectGamers);

  const filteredGamers = useFilterGamers(dataGamers, {
    sortByGamerId: true,
    excludeRole,
  });

  const mapGamers = filteredGamers.map(
    ({
      id,
      isActive,
      isKilled,
      isBlocked,
      role: { id: roleId, name, shortName, type, color },
    }) => ({
      id,
      roleId,
      isActive,
      isKilled,
      isBlocked,
      name,
      shortName,
      type,
      selected: false,
      color,
    })
  );

  const [gamers, setGamers] = useState<GamerListInterface[]>(mapGamers);

  useEffect(() => {
    if (isRerenderGamers) {
      setGamers(mapGamers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGamers]);

  const reset = () => {
    setGamers(gamers.map((gamer) => ({ ...gamer, selected: false })));
  };

  const canculateSelected = (
    selectedGamerId: number,
    gamer: GamerListInterface
  ) => {
    if (gamer.id === selectedGamerId) return true;

    return false;
  };

  const canculateMultipleSelected = (
    selectedGamerId: number,
    gamer: GamerListInterface
  ) => {
    if (gamer.id === selectedGamerId) return !gamer.selected;

    return gamer.selected;
  };

  const onSelectGamer = (id: number) => () => {
    const newGamers = gamers.map((gamer) => ({
      ...gamer,
      selected: isMultiple
        ? canculateMultipleSelected(id, gamer)
        : canculateSelected(id, gamer),
    }));

    if (isMultiple && onGamersSelect)
      onGamersSelect(
        newGamers.filter(({ selected }) => selected),
        { reset }
      );

    if (!isMultiple && onGamerSelect)
      onGamerSelect(
        newGamers.find(({ selected }) => selected) as GamerListInterface,
        { reset }
      );

    setGamers(newGamers);
  };

  const onSubmitHandler = (callback: GamerListSubmitType) => {
    const selectedGamer = gamers.find(
      ({ selected }) => selected
    ) as GamerListInterface;

    const selectedGamers = gamers.filter(
      ({ selected }) => selected
    ) as GamerListInterface[];

    callback({
      gamer: !isMultiple ? selectedGamer : null,
      gamers: isMultiple ? selectedGamers : [],
      actions: {
        reset: () => setGamers(mapGamers),
      },
    });
  };

  return {
    gamers,
    onSelectGamer,
    selectedGamers: gamers.filter(({ selected }) => selected),
    onSubmitHandler,
  };
};
