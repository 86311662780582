import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

import { DialogInterface } from "common/interfaces/DialogInterface";

// Define the initial state using that type
const initialState: DialogInterface = {
  icon: "info",
  title: "",
  description: "",
  isConfirmed: false,
  isRejected: false,
  isNext: false,
  isOpen: false,
  confirm: false,
  next: false,
  reject: false,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (_, action: PayloadAction<DialogInterface>) => {
      return { ...initialState, ...action.payload, isOpen: true };
    },
    setDialog: (state, action: PayloadAction<DialogInterface>) => {
      return { ...state, ...action.payload };
    },
    resetDialog: () => {
      return initialState;
    },
  },
});

export const { setDialog, resetDialog, openDialog } = dialogSlice.actions;

export const selectDialog = (state: RootState) => state.dialog;

export const dialogReducer = dialogSlice.reducer;
