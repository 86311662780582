import { Box, Button, Typography } from "@mui/material";
import { GamerList, GamerListElement } from "common/components";
import { useVoting } from "./hooks";

export const Voting = () => {
  const { onSumbit } = useVoting();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h1">Голосування</Typography>

      <GamerList isMultiple isRerenderGamers>
        <GamerListElement
          variant="submit"
          onSubmit={({ gamers }) => onSumbit(gamers.map(({ id }) => id))}
        >
          <Button
            sx={{
              width: "100%",
              marginTop: "30px",
            }}
            variant="contained"
          >
            Почати ніч
          </Button>
        </GamerListElement>
      </GamerList>
    </Box>
  );
};
