import { Box, Typography } from "@mui/material";
import { GamerCard, GamerBottom, DisabledGamer } from "./components";
import { useGamer } from "./hooks";
import { GamerInterface } from "common/interfaces";
import { OnFinishAbilityInterface } from "../../interfaces";
import Slide from "@mui/material/Slide";
import { useEffect, useState } from "react";

export interface Props {
  gamer: GamerInterface;
  onFinishAbility: OnFinishAbilityInterface;
}

export const Gamer = ({ gamer, onFinishAbility }: Props) => {
  const GamerComponent = useGamer(gamer.role);
  const gamerTitle =
    gamer.id !== 100 ? `${gamer.role.name} №-${gamer.id}` : gamer.role.name;

  const [isEffect, setEffect] = useState(true);

  const onFinish = (data: "success" | "fail") => {
    onFinishAbility(data);
    setEffect(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setEffect(true);
    }, 200);
  }, [gamer.id]);

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <Slide in={isEffect} direction="left">
        <Box>
          <GamerCard>
            <DisabledGamer gamer={gamer} onFinishAbility={onFinish} />

            <Typography variant="h1">{gamerTitle}</Typography>
            <Box
              sx={{ height: "150px" }}
              component="img"
              src={`img/gamers/${gamer.role.id}.svg`}
              alt=""
            />
            <GamerBottom>
              <GamerComponent gamer={gamer} onFinishAbility={onFinish} />
            </GamerBottom>
          </GamerCard>
        </Box>
      </Slide>
    </Box>
  );
};
