import { GamerList } from "common/components";
import { useAbility } from "common/hooks";
import { GamerPropsInterface } from "../../interfaces";

import { useScrounger, useBlockedGamers } from "./hooks";

export const Scrounger = ({ gamer, onFinishAbility }: GamerPropsInterface) => {
  const ability = useAbility(gamer.role.abilities[0]);

  const { onChangeGamerId } = useScrounger(onFinishAbility, gamer.id);
  const disableGamerId = useBlockedGamers();

  return (
    <>
      <GamerList
        disableGamerId={disableGamerId}
        title={`Використати здібність "${ability.name}"`}
        onGamerSelect={onChangeGamerId}
      />
    </>
  );
};
