import { useScene, useAlertDialog } from "common/hooks";
import { useDispatch } from "react-redux";
import { disableGamers } from "redux-store";

export const useVoting = () => {
  const dispatch = useDispatch();

  const { runScene } = useScene();

  const dialogConfirm = useAlertDialog({
    title: "Видалити гравців ?",
    type: "confirm",
  });

  const onSumbit = async (gamerIds: number[]) => {
    if (gamerIds.length) {
      const type = await dialogConfirm();

      if (type === "confirmed") {
        dispatch(disableGamers({ gamerIds }));
        runScene("nightActions", "Почати ніч ?");
      }
    }

    if (!gamerIds.length) {
      runScene("nightActions", "Почати ніч ?");
    }
  };

  return {
    onSumbit,
  };
};
