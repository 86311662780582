import { useFilterGamers, useScene } from "common/hooks";
import { useRef, useState } from "react";
import { selectGamers, useSelector } from "redux-store";

export const useSpeech = (onResetTimer: () => void, runStart: () => void) => {
  const gamers = useSelector(selectGamers);
  const filteredGamers = useFilterGamers(gamers, { sortByGamerId: true });

  const countGamers = filteredGamers.length;
  const gamerIndex = useRef(0);
  const isGamerStarted = useRef(false);
  const { runScene } = useScene();

  const [activeGamer, setActiveGamer] = useState(
    filteredGamers[gamerIndex.current]?.id
  );

  const [isEffect, setIsEffect] = useState(true);

  const runGamer = (type: "next" | "prew") => {
    setIsEffect(false);

    isGamerStarted.current = true;

    gamerIndex.current =
      type === "next" ? gamerIndex.current + 1 : gamerIndex.current - 1;

    const activeGamer = filteredGamers[gamerIndex.current];

    if (!activeGamer) return;

    setActiveGamer(activeGamer.id);

    setTimeout(() => {
      setIsEffect(true);
    }, 200);

    setTimeout(() => {
      onResetTimer();
    }, 300);
  };

  const onNextGamer = () => {
    if (gamerIndex.current === countGamers - 1) {
      return runScene("voting", "Перейти до голосувань ?");
    }

    if (gamerIndex.current < countGamers - 1) {
      runGamer("next");
    }
  };

  const onPrewGamer = () => {
    if (gamerIndex.current > 0) {
      runGamer("prew");
    }
  };

  const onStart = () => {
    isGamerStarted.current = true;
    runStart();
  };

  const onFinishTimer = () => {
    isGamerStarted.current = false;
  };

  return {
    countGamers,
    activeGamer,
    onNextGamer,
    onPrewGamer,
    onFinishTimer,
    onStart,
    isPrev: gamerIndex.current > 0,
    isNext: gamerIndex.current < countGamers - 1,
    isEffect,
  };
};
