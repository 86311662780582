import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./core/serviceWorkerRegistration";
import reportWebVitals from "./core/reportWebVitals";
import { ThemeProvider } from "@mui/material";
import theme from "./common/theme";
import { ResetStyles } from "./common/components";
import { Provider } from "react-redux";
import { store } from "./redux-store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ResetStyles />
      <App />
    </Provider>
  </ThemeProvider>
);

serviceWorkerRegistration.register();
reportWebVitals();
