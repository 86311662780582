import { IconDialogTypes } from "common/interfaces";

const colors: Record<IconDialogTypes, string> = {
  yes: "secondary.main",
  no: "secondary.main",
  info: "primary.main",
  warning: "secondary.main",
};

export const useDialogSetting = (variant: IconDialogTypes = "info") => {
  return {
    color: colors[variant],
  };
};
