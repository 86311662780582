import { OnFinishAbilityInterface } from "../../../interfaces";
import { useNightActions, usePushIncomingAbilityNew } from "../../../hooks";
import { useDialog, onGamerListSelectGamerInterface } from "common/components";
import { useAlertDialog, useGamers } from "common/hooks";

export const useDoctor = (
  onFinishAbility: OnFinishAbilityInterface,
  gamerId: number
) => {
  const { pushIncomingAbility } = usePushIncomingAbilityNew();

  const { getGamerById } = useGamers();

  const { registerNightAction } = useNightActions();

  const abilityDataDialog = useDialog();
  const alertDataDialog = useDialog();

  const confirmDialog = useAlertDialog({ type: "confirm" });

  const onHealingAbility = async (
    id: number,
    name: string,
    isSelf?: boolean,
    reset?: any
  ) => {
    const pushedGamer = getGamerById(id);

    const type = await confirmDialog({
      title: !isSelf ? `№${id} ${name}` : "Використати здібність на себе ?",
      description: !isSelf ? "Вилікувати гравця ?" : "",
    });

    if (type !== "confirmed") {
      return reset && reset();
    }

    pushIncomingAbility({
      pushedGamer,
      abilityId: "healing",
    });

    onFinishAbility("success");

    registerNightAction({
      abilityId: "healing",
      gamerIdFrom: gamerId,
      gamersIdsTo: [pushedGamer.id],
    });
  };

  const onPushSelfAbility = async () => {
    onHealingAbility(gamerId, "", true);
  };

  const onChangeGamerId: onGamerListSelectGamerInterface = (gamer, actions) => {
    onHealingAbility(gamer.id, gamer.name, false, actions.reset);
  };

  return {
    onChangeGamerId,
    onPushSelfAbility,
    abilityDataDialog,
    alertDataDialog,
  };
};
