import { GamerList } from "common/components";
import { useAbility } from "common/hooks";
import { GamerPropsInterface } from "../../interfaces";

import { useDetective } from "./hooks";
import { Button } from "@mui/material";

export const Detective = ({ gamer, onFinishAbility }: GamerPropsInterface) => {
  const ability = useAbility(gamer.role.abilities[0]);

  const { onConfirmAbility, onNext } = useDetective(onFinishAbility, gamer.id);

  return (
    <>
      <GamerList
        isMultiple
        onGamersSelect={(gamers, actions) =>
          onConfirmAbility(
            gamers.map(({ id }) => id),
            actions.reset
          )
        }
        title={`Використати здібність "${ability.name}"`}
      />
      <Button onClick={onNext} variant="contained">
        Гравців перевірено
      </Button>
    </>
  );
};
