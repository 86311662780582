import { Box, Button, Typography } from "@mui/material";
import { RoleList } from "./components";
import { useRoleSet } from "./hooks";

export const RoleSet = () => {
  const { items, onAutoset, onReset, isSetItemGamers, onNextStep } =
    useRoleSet();

  return (
    <Box>
      <Typography variant="h1">Встановлення ролей</Typography>
      {!isSetItemGamers && (
        <Button
          style={{ margin: "0 0 30px 0" }}
          variant="contained"
          onClick={onAutoset}
        >
          Встановити
        </Button>
      )}
      {isSetItemGamers && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <Button
            style={{ margin: "0 15px 0 0" }}
            variant="contained"
            onClick={onNextStep}
          >
            Знайомство
          </Button>
          <Button style={{ margin: "0" }} variant="contained" onClick={onReset}>
            Скинути
          </Button>
        </Box>
      )}
      <RoleList items={items} />
    </Box>
  );
};
