import { useSelector } from "react-redux";
import { selectNights } from "redux-store";

export const usePrevNigthHealing = () => {
  const nights = useSelector(selectNights);
  const prevNigth = nights[nights.length - 2];

  const prevNigthAction = prevNigth
    ? prevNigth.find(({ abilityId }) => abilityId === "healing")
    : null;

  return {
    prevNigthGamerId: prevNigthAction?.gamersIdsTo[0],
  };
};
