import { PresetTypesInterface } from "common/interfaces";

export const presetTypes: PresetTypesInterface[] = [
  {
    id: 1,
    type: 6,
    presets: [
      {
        id: 1,
        name: "Легка гра",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "sheriff",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 2,
        name: "Сильне місто",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "sheriff",
          "admirer",
          "peaceful",
        ],
      },
      {
        id: 3,
        name: "М'ясо",
        roles: [
          "doctor",
          "godfather",
          "sheriff",
          "maniac",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 4,
        name: "Активні ролі 1",
        roles: [
          "doctor",
          "godfather",
          "chameleon",
          "sheriff",
          "admirer",
          "peaceful",
        ],
      },
      {
        id: 5,
        name: "Активні ролі 2",
        roles: [
          "doctor",
          "godfather",
          "chameleon",
          "sheriff",
          "detective",
          "peaceful",
        ],
      },
    ],
  },
  {
    id: 2,
    type: 7,
    presets: [
      {
        id: 1,
        name: "Легка гра",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "sheriff",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 2,
        name: "Сильне місто",
        roles: [
          "doctor",
          "godfather",
          "sheriff",
          "admirer",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 3,
        name: "М'ясо",
        roles: [
          "doctor",
          "sheriff",
          "godfather",
          "mafiosi",
          "maniac",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 4,
        name: "Активні ролі 1",
        roles: [
          "doctor",
          "godfather",
          "chameleon",
          "sheriff",
          "admirer",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 5,
        name: "Активні ролі 2",
        roles: [
          "doctor",
          "godfather",
          "chameleon",
          "sheriff",
          "detective",
          "peaceful",
          "peaceful",
        ],
      },
    ],
  },
  {
    id: 3,
    type: 8,
    presets: [
      {
        id: 1,
        name: "Легка гра",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "mafiosi",
          "sheriff",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 2,
        name: "Сильне місто",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "mafiosi",
          "sheriff",
          "admirer",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 3,
        name: "М'ясо",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "sheriff",
          "maniac",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 4,
        name: "Активні ролі 1",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "chameleon",
          "sheriff",
          "admirer",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 5,
        name: "Активні ролі 2",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "chameleon",
          "sheriff",
          "detective",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 6,
        name: "Шерлок Холмс",
        roles: [
          "doctor",
          "godfather",
          "mafiosi",
          "detective",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
    ],
  },
  {
    id: 4,
    type: 9,
    presets: [
      {
        id: 1,
        name: "Класична гра",
        roles: [
          "doctor",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 2,
        name: "Шерлок Холмс",
        roles: [
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 3,
        name: "Ілюзія обману",
        roles: [
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "chameleon",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 4,
        name: "Пастка хаосу",
        roles: [
          "doctor",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "bomb",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
    ],
  },
  {
    id: 5,
    type: 10,
    presets: [
      {
        id: 1,
        name: "Класична гра",
        roles: [
          "doctor",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 2,
        name: "Шерлок Холмс",
        roles: [
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 3,
        name: "Ілюзія обману",
        roles: [
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "chameleon",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 4,
        name: "Пастка хаосу",
        roles: [
          "doctor",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "bomb",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
    ],
  },
  {
    id: 6,
    type: 11,
    presets: [
      {
        id: 1,
        name: "Класична гра",
        roles: [
          "doctor",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 2,
        name: "Шерлок Холмс",
        roles: [
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "mafiosi",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 3,
        name: "Ілюзія обману",
        roles: [
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "chameleon",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 4,
        name: "Пастка хаосу",
        roles: [
          "doctor",
          "maniac",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "bomb",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
    ],
  },
  {
    id: 7,
    type: 12,
    presets: [
      {
        id: 1,
        name: "Класична гра",
        roles: [
          "maniac",
          "doctor",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 2,
        name: "Шерлок Холмс",
        roles: [
          "maniac",
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "mafiosi",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 3,
        name: "Ілюзія обману",
        roles: [
          "doctor",
          "godfather",
          "detective",
          "angeldeath",
          "chameleon",
          "mafiosi",
          "mafiosi",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
      {
        id: 4,
        name: "Пастка хаосу",
        roles: [
          "doctor",
          "maniac",
          "godfather",
          "sheriff",
          "mafiosi",
          "mafiosi",
          "mafiosi",
          "bomb",
          "peaceful",
          "peaceful",
          "peaceful",
          "peaceful",
        ],
      },
    ],
  },
];
