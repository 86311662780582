import { Container } from "@mui/material";
import { Header, StartGame, ResetApp } from "common/components";
import { Scene } from "features/scenes";

function App() {
  return (
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: "background.default",
        minHeight: "100vh",
        padding: "15px 30px",
        position: "relative",
      }}
    >
      <ResetApp />
      <Header />
      <Scene />
      <StartGame />
    </Container>
  );
}

export default App;
