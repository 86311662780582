/* eslint-disable no-restricted-globals */
import { useScene, useSettingsCountGamers } from "common/hooks";
import { useRef, useState } from "react";

export const useAcquaintance = (
  onResetTimer: () => void,
  runStart: () => void
) => {
  const countGamers = useSettingsCountGamers();
  const gamerIndex = useRef(1);
  const isGamerStarted = useRef(false);
  const { runScene } = useScene();

  const [activeGamer, setActiveGamer] = useState(1);

  const [isEffect, setIsEffect] = useState(true);

  const runGamer = (type: "next" | "prew") => {
    setIsEffect(false);

    isGamerStarted.current = true;

    gamerIndex.current =
      type === "next" ? gamerIndex.current + 1 : gamerIndex.current - 1;

    setActiveGamer(gamerIndex.current);

    setTimeout(() => {
      setIsEffect(true);
    }, 200);

    setTimeout(() => {
      onResetTimer();
    }, 300);
  };

  const onNextGamer = () => {
    if (countGamers === gamerIndex.current) {
      return runScene("voting", "Перейти до голосувань ?");
    }

    if (gamerIndex.current <= countGamers) {
      runGamer("next");
    }
  };

  const onPrewGamer = () => {
    if (gamerIndex.current > 1) {
      runGamer("prew");
    }
  };

  const onStart = () => {
    isGamerStarted.current = true;
    runStart();
  };

  const onFinishTimer = () => {
    isGamerStarted.current = false;
  };

  return {
    countGamers,
    activeGamer,
    onNextGamer,
    onPrewGamer,
    onFinishTimer,
    onStart,
    isPrev: gamerIndex.current > 1,
    isNext: gamerIndex.current !== countGamers,
    isEffect,
  };
};
