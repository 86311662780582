import { List, ListItem, ListItemText } from "@mui/material";
import { GamerItemInterface } from "../interfaces";
import { RoleGamerList } from "./RoleGamerList";

interface Props {
  items: GamerItemInterface[];
}

export const RoleList = ({ items }: Props) => (
  <List component="nav" aria-label="mailbox folders">
    {items.map(({ id, name, gamers }) => (
      <ListItem
        key={id}
        style={{ color: "white" }}
        sx={{
          border: "1px solid",
          marginBottom: "15px",
          height: "60px",
        }}
      >
        <ListItemText primary={name} />
        <RoleGamerList gamers={gamers} />
      </ListItem>
    ))}
  </List>
);
