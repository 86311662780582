import { GamerList } from "common/components";
import { useAbility } from "common/hooks";
import { GamerPropsInterface } from "../../interfaces";

import { useManiac } from "./hooks";

export const Maniac = ({ gamer, onFinishAbility }: GamerPropsInterface) => {
  const ability = useAbility(gamer.role.abilities[0]);

  const { onChangeGamerId } = useManiac(onFinishAbility, gamer.id);

  return (
    <>
      <GamerList
        disableGamerId={gamer.id}
        title={`Використати здібність "${ability.name}"`}
        onGamerSelect={onChangeGamerId}
      />
    </>
  );
};
