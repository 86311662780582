import CachedIcon from "@mui/icons-material/Cached";
import { useAlertDialog } from "common/hooks";

declare const window: {
  location: {
    reload: (force?: boolean) => void;
  };
} & Window;

export const ResetApp = () => {
  const reloadDialog = useAlertDialog({ type: "confirm" });

  const reload = async () => {
    const type = await reloadDialog({
      title: "Почати гру заново ?",
    });

    // eslint-disable-next-line no-restricted-globals
    type === "confirmed" && window.location.reload();
  };

  return (
    <CachedIcon
      sx={{
        color: "white",
        position: "absolute",
        right: "5px",
        top: "5px",
        fontSize: "40px",
        zIndex: 100,
      }}
      onClick={reload}
    />
  );
};
