import { useAlertDialog, useGamers, useScene } from "common/hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { disableGamers, updateNigthGamersStatuses } from "redux-store";

export const useNigthResults = () => {
  const dispatch = useDispatch();
  const [disableGamersIds, onChangeDisableGamers] = useState<number[]>([]);

  const { gamers: killedNigthGamers } = useGamers({
    iskilledNigthGamers: true,
  });
  const { gamers: activeGamers } = useGamers({ isExcludeKilled: true });

  const { runScene } = useScene();

  const confirmDialog = useAlertDialog({
    title: "Перейти до балагану ?",
    type: "confirm",
  });

  const confirmDeleteDialog = useAlertDialog({
    title: "гравців видалено, перейти до балагану ?",
    type: "confirm",
  });

  const warningDialog = useAlertDialog({
    title: "Оберіть гравців !!!",
    type: "alert",
  });

  const onSubmitNextScene = (): void => {
    confirmDialog().then((type) => {
      if (type === "confirmed") {
        dispatch(updateNigthGamersStatuses());
        runScene("meeting");
      }
    });
  };

  const onSubmitDisableGamers = (): void => {
    if (!disableGamersIds.length) {
      warningDialog();
      return;
    }

    dispatch(disableGamers({ gamerIds: disableGamersIds }));

    onChangeDisableGamers([]);

    confirmDeleteDialog().then((type) => {
      if (type === "confirmed") {
        dispatch(updateNigthGamersStatuses());
        runScene("meeting");
      }
    });
  };

  return {
    killedNigthGamers,
    activeGamers,
    onSubmitNextScene,
    onSubmitDisableGamers,
    onChangeDisableGamers,
    disableGamersIds,
  };
};
