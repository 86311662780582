/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GamerListInterface } from "../interfaces";
import { ReactNode } from "react";

interface Props {
  selectedGamers: GamerListInterface[];
  children: ReactNode;
}

export const Header = ({ selectedGamers, children }: Props) => (
  <div
    css={css`
      color: white;
      font-size: 18px;
      margin-bottom: 5px;
      min-height: 55px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    `}
  >
    {selectedGamers.length === 0 && (
      <span color="primary.contrastText">{children}</span>
    )}
    {selectedGamers.map(({ id, name }) => (
      <span
        css={css`
          border: 1px solid white;
          border-radius: 5px;
          margin: 5px;
          padding: 10px;
          display: inline-block;
        `}
        key={id}
      >
        {`${id} `}
        {name}
      </span>
    ))}
  </div>
);
