import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import InfoIcon from "@mui/icons-material/Info";
import { IconDialogTypes } from "common/interfaces";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const icons = {
  yes: ThumbUpAltIcon,
  no: ThumbDownAltIcon,
  info: InfoIcon,
  warning: PriorityHighIcon,
};

export const useDialogIcon = (variant?: IconDialogTypes) => {
  return variant ? icons[variant] : null;
};
