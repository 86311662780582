import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GamerListMultipleSelect } from "common/components";
import { ActiveGamerList, KilledGamerList } from "./components";
import { useNigthResults } from "./hooks/useNigthResults";

export const NightResults = () => {
  const {
    killedNigthGamers,
    activeGamers,
    onSubmitNextScene,
    onChangeDisableGamers,
    onSubmitDisableGamers,
    disableGamersIds,
  } = useNigthResults();

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: "15px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography variant="h1">Розпочався день</Typography>

      <KilledGamerList gamers={killedNigthGamers} />
      <Button
        onClick={onSubmitNextScene}
        sx={{ marginTop: "15px" }}
        variant="contained"
      >
        Почати балаган
      </Button>

      <Typography variant="h2">Видалити гравців:</Typography>

      <GamerListMultipleSelect
        isExcludeKilled
        title="Оберіть номери"
        onSelectGamers={onChangeDisableGamers}
        onClose={onSubmitDisableGamers}
        resetValue={disableGamersIds.length}
      />

      <ActiveGamerList gamers={activeGamers} />
    </Box>
  );
};
