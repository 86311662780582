import { GamerList } from "common/components";
import { useAbility } from "common/hooks";
import { GamerPropsInterface } from "../../interfaces";

import { useSheriff } from "./hooks";
import { Button } from "@mui/material";

export const Sheriff = ({ gamer, onFinishAbility }: GamerPropsInterface) => {
  const abilityKilling = useAbility(gamer.role.abilities[1]);

  const { onChangeGamerId, onNext } = useSheriff(onFinishAbility, gamer.id);

  return (
    <>
      <GamerList
        disableGamerId={gamer.id}
        title={`Використати здібність "${abilityKilling.name}"`}
        onGamerSelect={onChangeGamerId}
      ></GamerList>
      <Button onClick={onNext} variant="contained">
        гравця перевірено
      </Button>
    </>
  );
};
