import shuffle from "lodash/shuffle";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import { useGamers, useRoles, useScene, useSettingsGamers } from "common/hooks";
import { GamerInterface, RoleInterface } from "common/interfaces";
import { useState } from "react";
import { GamerItemInterface } from "../interfaces";

const createGamer = (id: number, role: RoleInterface): GamerInterface => ({
  id,
  incomingAbilities: [],
  isActive: true,
  isKilled: false,
  isBlocked: false,
  role,
});

export const useRoleSet = () => {
  const { setGamers } = useGamers();
  const { presetRolesList, rolesList } = useRoles();
  const sttingsGamers = useSettingsGamers();
  const { runScene } = useScene();

  const defaultItems = presetRolesList.reduce((unique, current) => {
    if (!unique.some((role) => role.roleTypeId === current.roleTypeId)) {
      unique.push({ ...current, gamers: [] });
    }
    return unique;
  }, [] as GamerItemInterface[]);

  const [items, setItems] = useState<GamerItemInterface[]>(defaultItems);

  const [gamersIds] = useState(sttingsGamers.map(({ id }) => id));

  const isSetItemGamers = !!items.find(({ gamers }) => gamers.length);

  const onAutoset = () => {
    const shuffledGamersIds = shuffle([...gamersIds.reverse()]);
    const presetRoles = shuffle([...presetRolesList.reverse()]);

    const gamers = [];

    for (let i = 0; i < presetRoles.length; i++) {
      const role = presetRoles[i];
      const gamerId = Number(shuffledGamersIds[i]);

      for (const item of items) {
        item.gamers =
          item.roleTypeId === role.roleTypeId
            ? sortBy([...item.gamers, gamerId])
            : item.gamers;
      }

      gamers.push(
        createGamer(
          gamerId,
          rolesList.find(({ id }) => id === role.roleTypeId) as RoleInterface
        )
      );
    }

    setGamers(gamers);
    setItems([...sortBy(items, (obj: any) => get(obj, "gamers[0]", Infinity))]);
  };

  const onReset = () => {
    setItems(defaultItems);
  };

  const onNextStep = () => runScene("acquaintance");

  return {
    items,
    gamersIds,
    onAutoset,
    onReset,
    isSetItemGamers,
    onNextStep,
  };
};
