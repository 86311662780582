/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button } from "@mui/material";
interface Props {
  isStart: boolean;
  isNext: boolean;
  onNextGamer: () => void;
  onResetTimer: () => void;
  onStart: () => void;
}

export const Navigation = ({
  isStart,
  onNextGamer,
  onResetTimer,
  onStart,
  isNext,
}: Props) => (
  <div
    css={css`
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      gap: 15px;
      .MuiButton-root {
        max-width: 200px;
        margin: 0;
      }
      height: 70px;
    `}
  >
    {isStart && (
      <>
        <Button onClick={onNextGamer} variant="contained">
          {isNext ? "Продовжити" : "Голосування"}
        </Button>
        <Button
          css={css`
            margin-left: 15px !important;
            font-size: 16px;
          `}
          onClick={onResetTimer}
          variant="text"
        >
          Ще хвилина
        </Button>
      </>
    )}
    {!isStart && (
      <Button onClick={onStart} variant="contained">
        Почати
      </Button>
    )}
  </div>
);
