import { GamerInterface } from "common/interfaces";

export const gamers: GamerInterface[] = [
  {
    id: 1,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "scrounger",
      type: "mafia",
      shortName: "КР",
      color: "black",
      name: "Крадій",
      isActiveNight: true,
      isDisabled: false,
      priority: 1,
      desctiption:
        "Грає за мафію, Просинається вночі. краде можливість у гравця зробити свій хід",
      abilities: ["block"],
    },
  },
  {
    id: 2,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "doctor",
      name: "Лікарка",
      shortName: "ЛК",
      color: "red",
      type: "city",
      isActiveNight: true,
      isDisabled: false,
      priority: 2,
      desctiption:
        "Грає за мирних, Просинається вночі та лікує одного гравця, немає можливості лікувати себе чи іншого гравця 2 рази підряд",
      abilities: ["healing"],
    },
  },
  {
    id: 3,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "maniac",
      name: "Маніяк",
      shortName: "МН",
      type: "self",
      color: "blue",
      isActiveNight: true,
      isDisabled: false,
      priority: 3,
      desctiption:
        "Просинається вночі, Грає самостійно від мафії та мирних стріляє у одного з участників",
      abilities: ["killing"],
    },
  },
  {
    id: 4,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "godfather",
      name: "Хр батько",
      shortName: "ХБ",
      type: "mafia",
      color: "black",
      isActiveNight: false,
      isDisabled: false,
      priority: 4,
      desctiption:
        "Лідер мафії, Просинається вночі. Вирішує разом зі своєю командою в кого мафія буде стріляти вночі",
      abilities: [],
    },
  },
  {
    id: 5,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "chameleon",
      name: "Хамелеон",
      shortName: "ХМ",
      type: "mafia",
      color: "red",
      isActiveNight: false,
      isDisabled: false,
      priority: 4,
      desctiption:
        "Грає за мафію при перевірці завжди буде як звичайний мешканець міста",
      abilities: [],
    },
  },
  {
    id: 6,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "mafiosi",
      name: "Мафіозі",
      shortName: "МФ",
      type: "mafia",
      color: "black",
      isActiveNight: false,
      isDisabled: false,
      priority: 4,
      desctiption:
        "Грає за мафію якщо вбивають хрещеного батька займає його місце",
      abilities: ["killing"],
    },
  },
  {
    id: 7,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "sheriff",
      name: "Шериф",
      shortName: "ШР",
      type: "city",
      color: "red",
      isActiveNight: true,
      isDisabled: false,
      priority: 5,
      desctiption:
        "Грає за мирних, Шериф просинається у ночі та має змогу перевіряти або стріляти",
      abilities: ["checkPersonTeam", "killing"],
    },
  },

  {
    id: 8,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "detective",
      name: "Детектив",
      shortName: "ДТ",
      type: "city",
      color: "red",
      isActiveNight: true,
      isDisabled: false,
      priority: 6,
      desctiption:
        "Грає за мирних, просинається у ночі, вибирає двух гравців, та може дізнатися грають вони за одну команду або ні",
      abilities: ["comparePersonsTeam"],
    },
  },
  {
    id: 9,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "admirer",
      name: "Фанатка",
      shortName: "ФН",
      type: "city",
      color: "red",
      isActiveNight: true,
      isDisabled: false,
      priority: 7,
      desctiption:
        "Грає за мирних, просинається у ночі та обирає одного гравця, якщо цей гравець шериф то дізнається його роль",
      abilities: ["checkSheriffRole"],
    },
  },
  {
    id: 10,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "angeldeath",
      name: "Янгол смерті",
      shortName: "ЯН",
      type: "city",
      color: "red",
      isActiveNight: false,
      isDisabled: false,
      priority: 8,
      desctiption: "Грає за мирних",
      abilities: ["killing"],
    },
  },
  {
    id: 11,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "bomb",
      name: "Вибухівка",
      shortName: "ВБ",
      type: "city",
      color: "red",
      isActiveNight: false,
      isDisabled: false,
      priority: 8,
      desctiption: "Грає за мирних",
      abilities: ["killing"],
    },
  },
  {
    id: 12,
    isActive: true,
    isKilled: false,
    isBlocked: false,
    incomingAbilities: [],
    role: {
      id: "peaceful",
      name: "Мирний",
      shortName: "ММ",
      type: "city",
      color: "red",
      isActiveNight: false,
      isDisabled: false,
      priority: 8,
      desctiption: "Грає за мирних",
      abilities: [],
    },
  },
];
