import { Box, Button } from "@mui/material";

interface Props {
  isStart: boolean;
  isNext: boolean;
  onNextGamer: () => void;
  onResetTimer: () => void;
  onStart: () => void;
}

export const Navigation = ({
  isStart,
  onNextGamer,
  onResetTimer,
  onStart,
  isNext,
}: Props) => (
  <Box
    sx={{
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      gap: "15px",
      height: "70px",
      ".MuiButton-root": {
        maxWidth: "200px",
        margin: "0",
      },
    }}
  >
    {isStart && (
      <>
        <Button onClick={onNextGamer} variant="contained" color="inherit">
          {isNext ? "Продовжити" : "Голосування"}
        </Button>
        <Button
          sx={{
            fontSize: "16px",
          }}
          onClick={onResetTimer}
          variant="text"
          color="inherit"
        >
          Ще хвилина
        </Button>
      </>
    )}
    {!isStart && (
      <Button onClick={onStart} variant="contained" color="inherit">
        Почати
      </Button>
    )}
  </Box>
);
