import { useSelector, selectGamers, selectNights } from "redux-store";

export const useBlockedGamers = () => {
  const dataGamers = useSelector(selectGamers);
  const nights = useSelector(selectNights);

  const prevNigth = nights[nights.length - 2];
  const gamer = dataGamers.find(({ role: { id } }) => id === "scrounger");

  const prevNigthAction = prevNigth
    ? prevNigth.find(({ gamerIdFrom }) => gamerIdFrom === gamer?.id)
    : null;

  const blockedGamersIds = dataGamers
    .filter(
      ({ id, role: { type } }) =>
        type === "mafia" || prevNigthAction?.gamersIdsTo.includes(id)
    )
    .map(({ id }) => id);

  return blockedGamersIds;
};
