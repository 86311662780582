import { useAlertDialog } from "common/hooks";
import { SceneTypes } from "common/interfaces";

import { useDispatch, setScene } from "redux-store";

export const useScene = () => {
  const dispatch = useDispatch();

  const dialog = useAlertDialog({ type: "confirm" });

  const runScene = (sceneType: SceneTypes, title?: string) => {
    if (title) {
      return dialog({ title }).then((type) => {
        type === "confirmed" && dispatch(setScene(sceneType));
      });
    }

    dispatch(setScene(sceneType));
  };

  return { runScene };
};
