import { OnFinishAbilityInterface } from "../../../interfaces";

import { useNightActions, usePushIncomingAbilityNew } from "../../../hooks";
import { useAlertDialog, useGamers } from "common/hooks";
import { onGamerListSelectGamerInterface } from "common/components";

export const useSheriff = (
  onFinishAbility: OnFinishAbilityInterface,
  gamerId: number
) => {
  const { getGamerById } = useGamers();

  const { pushIncomingAbility } = usePushIncomingAbilityNew();

  const { registerNightAction } = useNightActions();

  const confirmDialog = useAlertDialog({ type: "confirm" });

  const onChangeGamerId: onGamerListSelectGamerInterface = async (
    gamer,
    { reset }
  ) => {
    const type = await confirmDialog({
      title: `№${gamer.id} ${gamer.name}`,
      description: "Вбити гравця ?",
    });

    if (type === "confirmed") return onConfirmAbility(gamer.id);

    reset();
  };

  const onConfirmAbility = (id: number) => {
    const pushedGamer = getGamerById(id);

    pushIncomingAbility({ pushedGamer, abilityId: "killing" });
    onFinishAbility("success");

    registerNightAction({
      abilityId: "killing",
      gamerIdFrom: gamerId,
      gamersIdsTo: [pushedGamer.id],
    });
  };

  return {
    onChangeGamerId,
    onNext: () => onFinishAbility("success"),
  };
};
